import { institution } from 'services/endpoints/institution'
import { providers } from 'services/endpoints/providers'
import { createCustomAsyncThunk, customAsyncThunk } from 'utils/createThunk'

const getProviders = createCustomAsyncThunk('providers/getProviders', 'get', institution.institution)
const getProvidersPatient = createCustomAsyncThunk('providers/getProvidersPatient', 'get', providers.patient)
const inviteStaff = createCustomAsyncThunk('providers/inviteStaff', 'post', providers.invite)
const deleteStaff = createCustomAsyncThunk('providers/deleteStaff', 'delete', providers.providers)
const changeStaffRole = createCustomAsyncThunk('providers/changeStaffRole', 'patch', providers.providers)
const editProviderProfile = customAsyncThunk('providers/editProfile', 'patch', providers.edit_profile)
const getProviderCalendar = createCustomAsyncThunk('providers/getProviderCalendar', 'get', providers.providers)
const getProviderByGpiNUmber = createCustomAsyncThunk('providers/getProviderByGpiNUmber', 'get', providers.provider_by_gpiNUmber)
const getStaffWithTime = createCustomAsyncThunk('providers/getStaffWithShift', 'get', providers.get_staff_with_time);

export {
  getProviders,
  getProvidersPatient,
  inviteStaff,
  deleteStaff,
  getProviderByGpiNUmber,
  changeStaffRole,
  editProviderProfile,
  getProviderCalendar,
  getStaffWithTime,
};
const base = '/service';

export const service = {
  service: `${base}`,
  category: `${base}/category`,
  service_categories: `${base}/categories`,
  service_details: `${base}/details`,
  bulk_category: `${base}/category/bulk`,
  bulk_service: `${base}/bulk`,
  // provider_category: `/providers/category`,
};

import { Button } from 'components/Buttons';
import FormWrapper from 'components/FormWrapper';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IndividualProfileSetup from './IndividualProfileSetup';
import InstitutionAccountSetup from './InstitutionAccountSetup';
import CompleteOnboarding from './CompleteOnboarding';
import PropTypes from 'prop-types';
import { setUserDetails, useAuthState } from 'store/modules/auth';
import { useAppDispatch } from 'hooks/useReduxHook';
import VerifyInstitution from './VerifyInstitution';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';

const CreateAccountForm = ({ userTypes, params = {} }) => {
  const [phone, setPhone] = useState({
    phoneNumber:  '',
    countryCode: ''
  });
  const [answer, setAnswer] = useState();
  const { invited, verifyInstitution, userType } = params;
  const {userDetails} = useAuthState()
  const [providerData, setProviderData] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    providedServiceType: '',
    institution: '',
  });
  const [institutionData, setInstitutionData] = useState({
    businessName: userDetails?.businessName ?? '',
    businessEmailAddress: userDetails?.businessEmailAddress ?? '',
    providedServiceType:userDetails?.providedServiceType ?? '',
  });

  const [onboardingData, setOnboardingData] = useState({
    qualification: '',
    gender: '',
    emailAddress: params?.email,
    signature: ''
  });
  const [selectedType, setSelectedType] = useState({});

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const buttonDisabled = () => {
    if (!userType) {
      if (invited === 'true') {
        return checkEmptyProperties(onboardingData);
      }
      return !selectedType.type;
    } else if (userType === 'provider') {
      if (answer === 'yes') {
        return checkEmptyProperties(providerData);
      } else {
        return !providerData.firstName || !providerData.lastName || !providerData.emailAddress || !providerData.providedServiceType
      }
    } else if (userType === 'institution') {
      return checkEmptyProperties(institutionData);
    }
  };

  const handleSubmit = () => {
    if (userType === 'provider') {
      if (providerData.institution !== '') {
        dispatch(setUserDetails({ phoneNumber: phone.phoneNumber, countryCode: phone.countryCode, ...providerData }));
        navigate('/create-account?verifyInstitution=true');
      } else {
        dispatch(setUserDetails({ phoneNumber: phone.phoneNumber, countryCode: phone.countryCode, ...providerData }));
        navigate(`/create-password?userType=${userType}`);
      }
    } else if (userType === 'institution') {
      dispatch(setUserDetails({ phoneNumber: phone.phoneNumber, countryCode: phone.countryCode, ...institutionData }));
      navigate(`/create-password?userType=${userType}`);
    } else if (verifyInstitution === 'true') {
      dispatch(setUserDetails({ phoneNumber: phone.phoneNumber, countryCode: phone.countryCode, ...providerData }));
      navigate(`/create-password?userType=provider`);
    } else {
      dispatch(setUserDetails({ phoneNumber: phone.phoneNumber, countryCode: phone.countryCode, ...onboardingData }));
      navigate(`/create-password?invited=${true}`);
    }
  };
  
  const proceed = (type) => {
    navigate(`/create-account?userType=${type}`);
  };

  const goBack = () => {
    if(userType) {
      dispatch(setUserDetails({countryCode: '234'}))
      setInstitutionData({
        businessName: '',
        businessEmailAddress: '',
        providedServiceType: ''
      })
    }
    navigate(-1)
  }

  return (
    <FormWrapper
      description="Ok, let’s set up your profile"
      caption="This is how you will appear on GabbiHealth"
      className="sm:h-full md:mt-12 mt-8 w-full sm:min-w-[420px] mx-auto"
      showBackbutton={userType || verifyInstitution ? true : false}
      onSubmit={() => (!userType && !invited && !verifyInstitution ? proceed(selectedType.type) : handleSubmit())}
      onClick={goBack}
    >
      {!userType && !invited && !verifyInstitution && (
        <div>
          <div className="mt-10">
            <p className="font-campton_r text-14 text-neutral_black">I am registering as</p>
            <div className="flex space-x-6  mt-3">
              {userTypes?.map((item) => (
                <div
                  key={item.title}
                  data-testid={`test-${item.type}`}
                  className={` ${selectedType.type === item.type
                    ? 'border-success bg-success_fade'
                    : 'bg-white border-neutral_stroke_2 '
                    } border rounded-2xl md:p-4 p-3 flex flex-col justify-between items-center cursor-pointer`}
                  onClick={() => setSelectedType(item)}
                >
                  <div
                    className={`${selectedType.type === item.type ? 'bg-success' : 'bg-[#EBF6FA]'
                      }  h-8 w-8 flex justify-center items-center rounded-full`}
                  >
                    <item.icon color={selectedType.type === item.type ? '#ffffff' : '#36A1C5'} />
                  </div>
                  <div className="text-center mt-6">
                    <p className="text-primary_shade_300 font-campton_m md:text-16 text-12">{item.title}</p>
                    <p className="text-neutral_body md:text-14 text-12 font-campton_r mt-1">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {userType === 'provider' && !verifyInstitution && (
        <IndividualProfileSetup
          formData={providerData}
          phone={phone}
          setPhone={setPhone}
          setFormData={setProviderData}
          answer={answer}
          setAnswer={setAnswer}
        />
      )}
      {userType === 'institution' && (
        <InstitutionAccountSetup 
          formData={institutionData} 
          setFormData={setInstitutionData} 
          phone={phone}
          setPhone={setPhone}
        />
      )}
      {invited === 'true' && (
        <CompleteOnboarding
          formData={onboardingData}
          setFormData={setOnboardingData}
          params={params}
          phone={phone}
          setPhone={setPhone}
        />
      )}
      {verifyInstitution && <VerifyInstitution />}
      {!verifyInstitution && (
        <div className={`${!userType && !invited ? 'mt-40' : 'mt-10'}`}>
          <Button
            name="Next"
            theme="blue"
            className="w-full h-12"
            // loading={false}
            disabled={buttonDisabled()}
          />
          {!invited && (
            <div
              className="py-2 w-full border border-neutral_stroke_2 flex justify-center items-center rounded-full mt-6 h-12 cursor-pointer hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary text-neutral_body"
              onClick={() => navigate('/')}
              data-testid="test-login"
            >
              <p className=" font-campton_r">
                Have an account? <span className="text-brand_secondary font-campton_m">Login</span>
              </p>
            </div>
          )}
        </div>
      )}
    </FormWrapper>
  );
};

export default CreateAccountForm;
CreateAccountForm.propTypes = {
  userTypes: PropTypes.array,
  invited: PropTypes.string,
  params: PropTypes.object,
  verifyInstitution: PropTypes.string,
};

import { createSlice } from '@reduxjs/toolkit'
import { getShift, getShifts, getStaffShifts } from './actions'

const initialState = {
    shifts: {},
    singleShift: {},
    singleStaffShift: {},
    loading: false
}

export const shift = createSlice({
    name: 'shift',
    initialState,
    reducers: {
        setSingleStaffShift: (state, action) => {
            state.singleStaffShift = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getShifts.fulfilled, (state, action) => {
                state.shifts = action?.payload?.data ?? {}
            })
            .addCase(getStaffShifts.fulfilled, (state, action) => {
                state.shifts = action?.payload?.data ?? {}
            })
            .addCase(getShift.fulfilled, (state, action) => {
                state.singleShift = action?.payload?.data ?? {}
            })
    }
})

export const { setSingleStaffShift } = shift.actions
export default shift.reducer
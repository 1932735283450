import { service } from 'services/endpoints/service'
import { createCustomAsyncThunk, customAsyncThunk } from 'utils/createThunk'

const getServices = createCustomAsyncThunk('services/getServices', 'get', service.service)
const createService = createCustomAsyncThunk('services/createService', 'post', service.service)
const updateService = createCustomAsyncThunk('services/updateService', 'patch', service.service)
const deleteService = createCustomAsyncThunk('services/deleteService', 'delete', service.service)
const createServiceCategory = customAsyncThunk('services/createServiceCategory', 'post', service.category)
const updateServiceCategory = createCustomAsyncThunk('services/updateServiceCategory', 'patch', service.category)
const deleteServiceCategory = createCustomAsyncThunk('services/deleteServiceCategory', 'delete', service.category)
const getServiceCategories = createCustomAsyncThunk('services/getServiceCategories', 'get', service.service_categories)
const getServiceDetails = createCustomAsyncThunk('services/getServiceDetails', 'get', service.service_details)
const getCategories = createCustomAsyncThunk('services/getCategories', 'get', service.provider_category);
const deleteCategory = createCustomAsyncThunk('services/deleteCategory', 'delete', service.provider_category);
const updateCategory = createCustomAsyncThunk('services/updateCategory', 'patch', service.provider_category);
// const createCategory = customAsyncThunk('services/createCategory', 'post', service.provider_category)
const createBulkCategory = customAsyncThunk('services/createServiceCategory', 'post', service.bulk_category);
const createBulkService = customAsyncThunk('services/createService', 'post', service.bulk_service);

export {
  getServices,
  createService,
  createServiceCategory,
  updateServiceCategory,
  updateService,
  getServiceDetails,
  getServiceCategories,
  deleteServiceCategory,
  deleteService,
  getCategories,
  deleteCategory,
  updateCategory,
  // createCategory,
  createBulkCategory,
  createBulkService,
};

import React, { useEffect, useState } from 'react';
// import careMedicine from '../assets/svg/care-medicine.svg';
// import searching from '../assets/svg/searching.svg';
// import onlineConsultationCare from '../assets/svg/online-consultation-care.svg';
import Slider1 from 'assets/images/slider-1.webp';
import Slider2 from 'assets/images/slider-2.webp';
import Slider3 from 'assets/images/slider-3.webp';

export default function Slider() {
  const [current, setCurrent] = useState(0);
  let messages = [
    {
      title: 'Robust EHR System',
      caption: 'Experience a user-friendly EHR system that enhances overall workflow.',
      image: `url(${Slider1})`,
      id: 0,
    },
    {
      title: 'Enhanced Security & Privacy',
      caption: 'Ensure the highest level of security and patient data privacy',
      image: `url(${Slider2})`,
      id: 1,
    },
    {
      title: 'Improved Patient Care Coordination',
      caption: 'Facilitate seamless collaboration between healthcare providers with real-time information',
      image: `url(${Slider3})`,
      id: 2,
    },
  ];

  useEffect(() => {
    let position = 0;
    const element = document.getElementById('slider');

    let timer = setInterval(() => {
      if (current + 1 < messages.length) {
        position = (current + 1) * 100;
        setCurrent((prevState) => prevState + 1);
      }
      if (current + 1 === messages.length) {
        position = 0;
        setCurrent(0);
      }
      element.style.transform = `translateX(-${position}%)`;
    }, 5000);

    return () => clearInterval(timer);
  }, [current]);

  return (
    <div className="relative">
      <div className="h-[100vh] flex transition ease-in-out duration-1000 text-neutral_white" id="slider">
        {messages.map((item) => (
          <div className="w-full shrink-0" key={item.id}>
            <section
              className="hidden sticky py-10 shrink-0 overflow-hidden top-0 lg:flex lg:flex-col lg:flex-[.4] h-full"
              style={{
                height: '100%',
                width: '100%',
                backgroundImage: item.image,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className="h-full flex justify-end flex-col">
                <div className="w-full shrink-0 md:px-10 2xl:px-60 xl:px-40">
                  <div className="text-center mb-20">
                    <h2 className="text-32 text-white font-campton_sb leading-[38px] mb-6">{item.title}</h2>
                    <p className="text-18 font-campton_r font-normal leading-24">{item.caption}</p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ))}
      </div>
      <div className="absolute bottom-8 left-0 right-0 m-auto flex space-x-1.5 justify-center w-24 items-center mx-auto h-3 mt-8 2xl:mt-16">
        {messages.map((item) => (
          <div
            className={`${
              item?.id === current ? 'bg-[#36A1C5] w-[70%]' : 'bg-[#ECEEEE] w-[15%]'
            } h-3 first-of-type:rounded-lg rounded-full`}
            key={item?.id}
          ></div>
        ))}
      </div>
    </div>
  );
}

import React, { useState } from "react"
import PropTypes from "prop-types"
import { AnimatePresence, motion } from "framer-motion"
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg"
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg"
import { useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import useWindowWidth from "hooks/useWindowWidth"
import { toggleDrawer } from "store/modules/global"
import { MEDIUM_WIDTH } from "constants"

export default function List({ showDrawer, name, icon, onClick, more = false, toggle, url }) {
    const newName = () => {
        if (name === 'Service Mgt.') return 'Service'
        if (name === 'Organisation Mgt.') return 'Settings'
        return name
    }
    const [showLabel, setShowLabel] = useState(false)
    const { pathname } = useLocation()
    const regex = new RegExp(url?.toLowerCase() || newName()?.toLowerCase())
    const match = regex.exec(pathname)
    const dispatch = useDispatch();
    const windowWidth = useWindowWidth()

    return (
        <div
            data-testid={name}
            className={`
                flex ${showDrawer ? 'justify-between' : 'justify-center'} items-center rounded-[100px] cursor-pointer 
                ${match ? 'bg-brand_primary font-campton_m' : 'hover:bg-[#304D78] font-campton_r'} py-[9px] px-[14px] mb-[16px]
            `}
            onMouseOver={() => setShowLabel(true)}
            onMouseOut={() => setShowLabel(false)}
            onClick={() => {
                if (windowWidth < MEDIUM_WIDTH && !more) dispatch(toggleDrawer())
                onClick()
            }}
        >
            <div className="flex items-center">
                {icon}
                <p className={`${showDrawer ? 'block' : 'hidden'} text-14 text-neutral_white shrink-0`}>{name}</p>
            </div>
            {more && <div className="flex">{toggle ? <ArrowUp fill="#ffffff" /> : <ArrowDown fill="#ffffff" />}</div>}
            <AnimatePresence>
                {showLabel &&
                    <motion.div
                        initial={{ opacity: 0, x: "-10px" }}
                        animate={{ opacity: 1, x: 0, transition: { type: 'spring', duration: .7 } }}
                        exit={{ opacity: 0, transition: { duration: .1 } }}
                        className={showDrawer ? 'hidden' : 'absolute left-[30px] flex items-center z-20'}
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-[18px]">
                            <rect x="-1" y="10" width="15.5147" height="15.5147" rx="2.58579" transform="rotate(-45 -1 10)" fill="#143566" />
                        </svg>
                        <p className='text-14 text-neutral_white shrink-0 bg-blue px-[16px] py-[6px] whitespace-nowrap rounded-[4px]'>{name}</p>
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    )
}

List.propTypes = {
    showDrawer: PropTypes.bool,
    name: PropTypes.string,
    url: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func,
    more: PropTypes.bool,
    toggle: PropTypes.bool
}
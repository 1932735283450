const base = '/providers';

export const providers = {
  invite: `${base}/invite`,
  providers: `${base}`,
  patient: `${base}/patient`,
  edit_profile: `${base}/edit-profile`,
  get_appointments: `${base}/appointments`,
  provider_by_gpiNUmber: `${base}/gpiNumber`,
  get_staff_with_time: `${base}/staff-with-time`
};

import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useLocation, useNavigate } from "react-router-dom"
import { AnimatePresence, motion } from "framer-motion"
import useWindowWidth from "hooks/useWindowWidth"
import { useDispatch } from "react-redux"
import { MEDIUM_WIDTH } from "constants"
import { toggleDrawer } from "store/modules/global"

export default function SubMenu({ condition, options, type, showDrawer }) {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const windowWidth = useWindowWidth()
    const dispatch = useDispatch();

    const test = useCallback((item) => {
        const regex = new RegExp(item?.toLowerCase())
        return regex.exec(pathname)
    }, [pathname])

    return (
        <AnimatePresence>
            {condition ?
                <motion.div
                    initial={{ opacity: 0, y: "-10px" }}
                    animate={{ opacity: 1, y: 0, transition: { type: 'spring', duration: .7 } }}
                    exit={{ opacity: 0, y: "-10px", transition: { duration: .1 } }}
                    className="bg-[#304D78] rounded-[16px] max-h-[169px] px-[12px] overflow-auto mb-[16px]"
                >
                    {options?.filter(item => item?.permission)?.map((item) =>
                        <div
                            key={item?.value}
                            data-testid={item?.name}
                            className="py-[12px] cursor-pointer flex items-center justify-between"
                            onClick={() => {
                                if (windowWidth < MEDIUM_WIDTH) dispatch(toggleDrawer(false))
                                navigate(`/${type}/${item?.value}`)
                            }}
                        >
                            <div className="flex items-center">
                                {item?.icon}
                                <p className={`${showDrawer ? 'block' : 'hidden'} text-14 text-neutral_white font-campton_r`}>{item?.name}</p>
                            </div>
                            {test(item.value) ?
                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="white" />
                                </svg> : ''
                            }
                        </div>
                    )}
                </motion.div> : ''
            }
        </AnimatePresence>
    )
}

SubMenu.propTypes = {
    showDrawer: PropTypes.bool,
    options: PropTypes.array,
    type: PropTypes.string,
    condition: PropTypes.bool,
}
import { createSlice } from '@reduxjs/toolkit';
import {
  getServiceCategories,
    getServices,
    getCategories,
    updateServiceCategory,
    getServiceDetails
  } from './actions';
  import { useAppSelector } from 'hooks/useReduxHook';

  const initialState = {
    services: [],
    categories: [],
    serviceCategories: [],
    selectedCategory: {},
    selectedService: {},
    serviceDetails: {}
  };

  export const services = createSlice({
    name: 'services',
    initialState,
    reducers: {
        setCategory: (state = initialState, action) => {
          state.selectedCategory = action.payload;
        },
        setService: (state = initialState, action) => {
          state.selectedService = action.payload;
        }
    },
    extraReducers: (builder) => {
      builder
      .addCase(getServices.fulfilled, (state, action) => {
        state.services = action?.payload?.data;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action?.payload?.data;
      })
      .addCase(getServiceCategories.fulfilled, (state, action) => {
        state.serviceCategories = action?.payload?.data;
      })
      .addCase(updateServiceCategory.fulfilled, (state, action) => {
        state.selectedCategory = action?.payload?.data;
      })
      .addCase(getServiceDetails.fulfilled, (state, action) => {
        state.serviceDetails = action?.payload?.data;
        state.selectedService = action.payload?.data
      })
    }
});
// Selectors
const selectServices = (state) => state.services;
//Reducers and actions
export const {setCategory, setService} = services.actions
//App Redux State
export const useServiceState = () => useAppSelector(selectServices);
export default services.reducer;
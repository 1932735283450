import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components/Inputs';
import Phone from 'components/Inputs/PhoneInput';
import Dropdown from 'components/Dropdown';
import { genderTypes } from 'mocks/auth';
import AddElectronicSignature from './ElectronicSignature/AddSignature';

const CompleteOnboarding = ({ formData, setFormData, params = {}, phone, setPhone }) => {
  const [gender, setGender] = useState();
  // const [qualification, setQualification] = useState();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <div className="mb-6 pt-6">
        <Input
          label="Full Name"
          type="text"
          id="fullName"
          name="fullName"
          disabled={true}
          value={params?.fullName}
          onChange={handleChange}
          showError={false}
        />
      </div>
      <Dropdown
        type="select"
        id="location"
        placeholder="Location"
        name="location"
        width="mb-6"
        selected={params.location}
        disabled={true}
      />
      <div className="mb-6">
        <Input
          label="Qualifications"
          type="text"
          id="qualifications"
          name="qualifications"
          value={params?.qualification}
          onChange={(e) => setFormData({ ...formData, qualification: e.target.value })}
          showError={false}
        />
      </div>
      {/* <Dropdown
        type="select"
        id="qualifications"
        placeholder="Qualifications"
        name="qualifications"
        width="mb-6"
        options={qualifications}
        selected={qualification}
        onSelect={(data) => {
          setQualification(data.name);
          setFormData({ ...formData, qualification: data.value });
        }}
      /> */}
      <Phone value={phone} onChange={setPhone} />
      <Dropdown
        type="select"
        id="gender"
        placeholder="Gender"
        name="gender"
        width="mb-6"
        options={genderTypes}
        selected={gender}
        onSelect={(data) => {
          setGender(data.name);
          setFormData({ ...formData, gender: data.value });
        }}
      />
      <div>
        <h2 className="text-16 sm:text-16 text-[#0D0F11] font-campton_m">Signature</h2>
      </div>
      <AddElectronicSignature setImageUrl={(val) => setFormData({ ...formData, signature: val })} />
    </div>
  );
};

export default CompleteOnboarding;
CompleteOnboarding.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  params: PropTypes.object,
  phone: PropTypes.object,
  setPhone: PropTypes.func
};

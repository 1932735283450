import { createCustomAsyncThunk } from 'utils/createThunk'
import { shift } from '../../../services/endpoints'

const createShift = createCustomAsyncThunk('shift/createShift', 'post', shift.shift)
const getShifts = createCustomAsyncThunk('shift/getShifts', 'get', shift.shift)
const getStaffShifts = createCustomAsyncThunk('shift/getStaffShifts', 'get', shift.staffShifts)
const getShift = createCustomAsyncThunk('shift/getShift', 'get', shift.shift)
const deleteShift = createCustomAsyncThunk('shift/deleteShift', 'delete', shift.shift)
const deleteShiftStaff = createCustomAsyncThunk('shift/deleteShiftStaff', 'delete', shift.shift)

export {
    createShift,
    deleteShift,
    deleteShiftStaff,
    getShift,
    getShifts,
    getStaffShifts
}
import { unwrapResult } from "@reduxjs/toolkit"
import { notify } from "../store/modules/global"
import { useDispatch } from "react-redux"
import useHandleError from "./useHandleError"
import { useNavigate } from "react-router-dom"
import instance from "services/axios-instance"
import { removeSpecificKeys } from "utils/removeSpecificKeys"

export default function useMakeRequest() {
    let dispatch = useDispatch()
    const { handleError } = useHandleError()
    let navigate = useNavigate();

    const makeRequest = ({ action, callback, alert = true, successMessage, to }) => {
        return dispatch(action)
            .then(unwrapResult)
            .then(res => {
                if (!Object.keys(res).length) return;

                if ([200, 201].includes(res?.code) || res?.error === false) {
                    if (alert) dispatch(notify({ display: true, status: 'success', message: successMessage || res?.message }))
                    if (to) navigate(to)
                    if (callback) callback()
                    return res
                }
                if (res?.message === 'Token expired' && res?.code === 401) return res
                if (![200, 201].includes(res?.code) || !res?.error) {
                    dispatch(notify({ display: true, status: 'error', message: handleError({ status: res?.code, message: res?.message }) }))
                }
                // if (!res?.code || !res?.error) {
                //     console.log('got here2');
                //     dispatch(notify({ display: true, status: 'error', message: 'Internal Server Error' }))}
                return res
            })
            .catch(error => error)
    }

    const makeAnonymousRequest = async ({ url, method, alert = true, payload, params, callback }) => {
        try {
            const removeEmptyParams = removeSpecificKeys({ ...params })
            const body = { ...payload }
            const queryParams = params ? '?' + new URLSearchParams(removeEmptyParams).toString() : ''
            const response = await instance[method || 'get'](`${url}${queryParams}`, body)
            const data = response?.data || response?.response?.data
            
            if (data?.message === 'Token expired' && data?.code === 401) return data
            if ([200, 201].includes(data?.code)) {
                if (alert) dispatch(notify({ display: true, status: 'success', message: data?.message }))
                if (callback) callback()
            }
            if (![200, 201].includes(data?.code) || !data?.code) {
                dispatch(
                    notify({
                        display: true,
                        status: 'error',
                        message: handleError({ status: data?.code, message: data?.message || 'Internal Server Error' })
                    })
                )
            }
            return data
        } catch (error) {
            return error
        }
    }

    return { makeRequest, makeAnonymousRequest }
}
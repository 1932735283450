import React from "react"
import PropTypes from "prop-types"
import SubMenu from "../SubMenu"
import { ReactComponent as Service } from 'assets/icons/service.svg';
import { ReactComponent as Bills } from 'assets/icons/bills.svg';
import usePermission from "hooks/usePermission";

export default function ServiceOption({ showDrawer, toggleService, iconMargin }) {
  const { hasModulePermission } = usePermission()

  const settings_options = [
    {
      name: 'Services',
      value: 'list',
      icon: <Service className={iconMargin} fill="#FFFFFF" />,
      permission: hasModulePermission('Service Management')
    },
    {
      name: 'Bill & Payment',
      value: 'bill-and-payment',
      icon: <Bills className={`${iconMargin} w-[20px]`} fill="#FFFFFF" />,
      permission: hasModulePermission('Bills and Payment')
    },
  ];

  return (
    <SubMenu
      options={settings_options}
      condition={toggleService}
      type='service'
      showDrawer={showDrawer}
    />
  )
}

ServiceOption.propTypes = {
  showDrawer: PropTypes.bool,
  toggleService: PropTypes.bool,
  iconMargin: PropTypes.string
}